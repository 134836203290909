@import "../../mixins.scss";

.InputPhone {
  margin-bottom: 16px;
}

.InputPhone__label {
  @include inputLabel;
}

.InputPhone__field {
  @include global(".PhoneInputInput") {
    @include input();
  }
}

.InputPhone__field[data-error="true"] {
  @include global(".PhoneInputInput") {
    color: var(--alert);
    border: solid 1px var(--alert);
    background-color: rgba(255, 231, 231, 0.5);
  }
}
