@import "../../mixins.scss";

.Flex {
  display: flex;

  &[data-gap] > * {
    min-width: 0;
    padding-left: calc(var(--flexGap) / 2);
    padding-right: calc(var(--flexGap) / 2);
  }
}
