@import "../../mixins.scss";

.InputSelect2 {
  margin-bottom: 16px;
}

.InputSelect2__label {
  @include inputLabel;
}

.InputSelect2__group {
  position: relative;
}

.InputSelect2__wrapField {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 6px);
    right: 22px;
    width: 9px;
    height: 9px;
    transform: translateY(0px) rotateZ(45deg);
    pointer-events: none;
    border-right: solid 2px var(--primary);
    border-bottom: solid 2px var(--primary);
    border-color: var(--grey-70);
  }

  &[data-disabled="true"]::after {
    border-color: var(--grey-30);
  }
}

.InputSelect2__field {
  @include input();

  padding-right: 40px;

  &[data-option-disabled="true"] {
    color: var(--grey-30);
  }
}

.InputSelect2__field__loading {
  pointer-events: none;
  position: absolute;
  top: calc(50% - 10px);
  right: 42px;
}

.InputSelect2__valid {
  font-size: 18px;
  color: var(--success);
}
.InputSelect2__notValid {
  font-size: 18px;
  color: var(--alert);
}
.InputSelect2__icon {
  font-size: 18px;
  color: var(--primary);
}

/* LIST */
.InputSelect2__list {
  z-index: 9999;
  position: absolute;
  display: block;
  width: 100%;
  border-radius: 4px;
  border: solid 1px var(--grey-light);
  box-shadow: 1px 1px 10px rgba(black, 0.1);
  margin-top: 7px;
  padding: 6px 0px;

  background-color: var(--white);
  opacity: 1;
  visibility: visible;

  &[aria-hidden="true"] {
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 200ms, opacity 200ms linear;
  }
}

.InputSelect2__list__loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(white, 0.8);
}

.InputSelect2__list__notFound {
  padding: 5px 10px;
}

.InputSelect2__list__wrap {
  display: block;
  min-height: 45px;
  max-height: 157px;
  overflow: auto;
}

.InputSelect2__list__ul {
}

.InputSelect2__list__li {
  position: relative;
  font-size: 18px;
  cursor: pointer;
  padding: 5px 10px 5px 25px;
  margin-bottom: 1px;
  color: var(--primary);

  &:hover {
    color: var(--white);
    background-color: var(--grey-50);
  }

  &[aria-selected="true"] {
    animation: animeName 0.3s linear 0s 1 normal;
  }

  &[data-disabled="true"] {
    color: var(--grey-30);
  }
}

.InputSelect2__list__icon {
  position: absolute;
  left: 5px;
}

.InputSelect2__list__chevron {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes animeName {
  0% {
    background-color: var(--grey-50);
  }
  10% {
    color: var(--white);
    background-color: var(--grey-50);
  }
  20% {
    color: var(--primary);
    background-color: transparent;
  }
  50% {
    color: var(--primary);
    background-color: transparent;
  }
  60% {
    color: var(--white);
    background-color: var(--grey-50);
  }
  100% {
    background-color: var(--grey-50);
  }
}
