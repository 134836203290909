@import "../../mixins.scss";

.InputMedias {
  position: relative;
  margin-bottom: 16px;
}

.InputMedias__label {
  @include inputLabel;
  margin-bottom: 8px;
}

.InputMedias__wrap {
  position: relative;
}

.InputMedias__button {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  border: solid 1px var(--grey-20);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 15px;
  padding: 9px;
  color: var(--grey-60);
  cursor: pointer;

  &[data-error="true"] {
    border: solid 1px var(--alert);
    color: var(--alert);
  }

  &[data-dragging="true"] {
    border: dashed 1px var(--grey-20);
    background-color: var(--grey-10);
  }
}

.InputMedias__fileName {
}

.InputMedias__field {
  position: absolute;
  left: -100vw;
  opacity: 0;
}

.InputMedias__thumbnail {
  @extend .InputMedias__button;
  background-color: var(--grey-05);
  position: relative;
  cursor: default;
}

.InputMedias__remove {
  @include clear-button();

  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  padding: 10px;
  background-color: var(--white);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.InputMedias__media {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 9px;

  &[data-zoom="true"] {
    cursor: pointer;
  }
}

.InputMedias__loading {
  @extend .InputMedias__button;

  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(white, 0.5);
  cursor: default;
}
