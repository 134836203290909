@import "../../mixins.scss";

.InputRadio {
  margin-bottom: 16px;
}

.InputRadio__field {
  position: absolute;
  left: -100vw;
  opacity: 0;
}

.InputRadio__group {
  display: flex;
  margin-bottom: 10px;
}

.InputRadio__label {
  @include inputLabel;
  margin: 0;
}

.Checkbox__status {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 18px;
  margin-top: 0px;
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 1px solid var(--grey-medium);
  border-radius: 50%;

  .InputRadio__field:checked ~ & {
    border: 1px solid var(--primary);
    background: var(--primary);

    &::after {
      content: "";
      display: block;
      width: 9px;
      height: 5px;
      border-left: solid 1px var(--white);
      border-bottom: solid 1px var(--white);
      transform: rotate(-45deg);
      margin-top: -2px;
    }
  }

  &[data-error="true"] {
    border-color: var(--alert);
  }

  &[data-disabled="true"] {
    border-color: var(--grey-light);
  }

  .InputRadio__field:checked ~ &[data-disabled="true"] {
    border: 1px solid var(--grey-light);
    background: var(--grey-light);
  }

  .InputRadio__field:focus-visible ~ & {
    box-shadow: 0 0 0 3px var(--grey-ultra-light);
  }
}
