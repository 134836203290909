@import "../../mixins.scss";

.Col {
  position: relative;
  width: var(--col, auto);
  flex: 0 1 var(--col, auto);
  left: var(--offset, auto);

  @include breakpoint(xs) {
    width: var(--colxs, var(--col, auto));
    flex: 0 1 var(--colxs, var(--col, auto));
  }

  @include breakpoint(sm) {
    width: var(--colsm, var(--colxs, var(--col, auto)));
    flex: 0 1 var(--colsm, var(--colxs, var(--col, auto)));
  }

  @include breakpoint(md) {
    width: var(--colmd, var(--colsm, var(--colxs, var(--col, auto))));
    flex: 0 1 var(--colmd, var(--colsm, var(--colxs, var(--col, auto))));
  }

  @include breakpoint(lg) {
    width: var(
      --collg,
      var(--colmd, var(--colsm, var(--colxs, var(--col, auto))))
    );
    flex: 0 1
      var(--collg, var(--colmd, var(--colsm, var(--colxs, var(--col, auto)))));
  }

  @include breakpoint(xl) {
    width: var(
      --colxl,
      var(--collg, var(--colmd, var(--colsm, var(--colxs, var(--col, auto)))))
    );
    flex: 0 1
      var(
        --colxl,
        var(--collg, var(--colmd, var(--colsm, var(--colxs, var(--col, auto)))))
      );
  }

  @include breakpoint(xxl) {
    width: var(
      --colxxl,
      var(
        --colxl,
        var(--collg, var(--colmd, var(--colsm, var(--colxs, var(--col, auto)))))
      )
    );

    flex: 0 1
      var(
        --colxxl,
        var(
          --colxl,
          var(
            --collg,
            var(--colmd, var(--colsm, var(--colxs, var(--col, auto))))
          )
        )
      );
  }
}
