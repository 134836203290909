.ExhibitorList__status {
  &[data-key="Transmis"] {
    color: rgb(17, 100, 234);
  }
  &[data-key="Enregistré"] {
    color: var(--success);
  }
  &[data-key="En cours"] {
    color: rgb(234, 118, 17);
  }
  &[data-key="En attente"] {
    color: var(--grey-40);
  }
}

.ExhibitorList__collectif {
  max-width: 250px;
  margin: auto;
}

.blur {
  filter: blur(5px);
}

.ExhibitorList__outlet {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(black, 0.5);
  z-index: 9;
  overflow: auto;
  padding: 150px 50px;
}

.ExhibitorList__outlet__close {
  z-index: 999999;
  position: fixed;
  top: 180px;
  right: 30px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.ExhibitorList__outlet__content {
  margin: auto;
  max-width: 860px;
  background-color: white;
  border-radius: 4px;
}
.ExhibitorList__remove {
  vertical-align: top;
  font-family: inherit;
  text-align: left;
  background: none;
  line-height: normal;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  appearance: none;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  // color: inherit;
  -webkit-border-radius: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;

  &:focus-visible {
    outline: var(--primary) solid 2px;
    outline-offset: 3px;
    outline-style: auto;
  }
}
.TableContainer {
  max-height: 400px; /* Set your desired max-height */
  overflow-y: auto;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  position: relative;
}

.Table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Ensures columns align correctly */
}

.Table thead {
  position: sticky;
  top: 0;
  background: white; /* Optional: Background color to prevent overlay issues */
  z-index: 1; /* Ensures header stays on top */
}

.TableTh, .TableTd {
  padding: 8px; /* Adjust padding as needed */
  border: 1px solid darkgray; /* Optional: For better visibility */
}

.ExhibitorList__row__div {
  padding: 8px; /* Adjust padding as needed */
}

.ExhibitorList__row .ExhibitorList__row__div {
  padding: 20px 10px;
  text-align: center;
  max-height: 100px;
  overflow: hidden;
}

.ExhibitorList__row[data-removed="true"] .ExhibitorList__row__div {
  transition: all 300ms ease;
  padding: 0px 10px;
  max-height: 0;
}
