@use "sass:map";
@import "../mixins.scss";

@mixin render($styles) {
  @each $name, $data in $styles {
    $class: map-get($data, "class");
    $pseudo: map-get($data, "pseudo");
    $values: map-get($data, "values");
    $properties: map-get($data, "property");
    @each $key, $value in $values {
      .#{$class}#{"" + $key}#{$pseudo} {
        @each $p in $properties {
          @if map-get($data, "content") {
            #{$p}: "#{$value}";
          } @else {
            #{$p}: #{$value};
          }
        }
      }
    }
  }
}

$width: (
  1: 1px,
  2: $spacer,
  3: $spacer * 1.5,
  4: $spacer * 2,
  5: $spacer * 2.5,
  6: $spacer * 3,
  7: $spacer * 3.5,
  8: $spacer * 4,
  9: $spacer * 4.5,
  10: $spacer * 5,
  15: $spacer * 7.5,
  20: $spacer * 10,
  30: $spacer * 15,
  40: $spacer * 20,
  50: $spacer * 25,
);

$borderWidth: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
  6: 6px,
  7: 7px,
  8: 8px,
  9: 9px,
  10: 10px,
  15: 15px,
  20: 20px,
  30: 30px,
  40: 40px,
  50: 50px,
);

$opacity: (
  0: 0,
  10: 0.1,
  20: 0.2,
  30: 0.3,
  40: 0.4,
  50: 0.5,
  60: 0.6,
  70: 0.7,
  80: 0.8,
  90: 0.9,
  100: 1,
);

// $colors: ();
// @each $id, $opacity in $opacities {
//   $colors: map.set($colors, "primary-#{$id}", "var(--primary-#{$id})");
//   $colors: map.set($colors, "secondary-#{$id}", "var(--secondary-#{$id})");
//   $colors: map.set($colors, "tertiary-#{$id}", "var(--tertiary-#{$id})");
//   $colors: map.set($colors, "white-#{$id}", "var(--white-#{$id})");
// }

// $colors: map.merge(
//   (
//     primary: var(--primary),
//     secondary: var(--secondary),
//     tertiary: var(--tertiary),
//     alert: var(--alert),
//     warning: var(--warning),
//     info: var(--info),
//     success: var(--success),
//     white: var(--white),
//     black: var(--black),
//     "grey-cold": var(--grey-cold),
//     "grey-medium": var(--grey-medium),
//     "grey-light": var(--grey-light),
//     "grey-ultra-light": var(--grey-ultra-light),
//     "grey-05": var(--grey-05),
//     "grey-10": var(--grey-10),
//     "grey-20": var(--grey-20),
//     "grey-30": var(--grey-30),
//     "grey-40": var(--grey-40),
//     "grey-50": var(--grey-50),
//     "grey-60": var(--grey-60),
//     "grey-70": var(--grey-70),
//     "grey-80": var(--grey-80),
//     "grey-90": var(--grey-90),
//   ),
//   $colors
// );

$colors: (
  primary: var(--primary),
  secondary: var(--secondary),
  tertiary: var(--tertiary),
  alert: var(--alert),
  warning: var(--warning),
  info: var(--info),
  success: var(--success),
  white: var(--white),
  black: var(--black),
  "grey-cold": var(--grey-cold),
  "grey-medium": var(--grey-medium),
  "grey-light": var(--grey-light),
  "grey-ultra-light": var(--grey-ultra-light),
  "grey-05": var(--grey-05),
  "grey-10": var(--grey-10),
  "grey-20": var(--grey-20),
  "grey-30": var(--grey-30),
  "grey-40": var(--grey-40),
  "grey-50": var(--grey-50),
  "grey-60": var(--grey-60),
  "grey-70": var(--grey-70),
  "grey-80": var(--grey-80),
  "grey-90": var(--grey-90),
  "transparent": var(--transparent),
  "brand-primary-dark": var(--brand-primary-dark),
  "brand-primary-light": var(--brand-primary-light),
  "brand-secondary": var(--brand-secondary),
  "brand-tertiary": var(--brand-tertiary),
);
