@import "../../mixins.scss";

.Loading {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &[data-absolute="true"] {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.Loading__svg {
  width: 32px;
}

.Loading__svg path {
  fill: var(--secondary);

  .Loading[data-color="white"] & {
    fill: var(--white);
  }
  .Loading[data-color="primary"] & {
    fill: var(--primary);
  }
  .Loading[data-color="secondary"] & {
    fill: var(--secondary);
  }
  .Loading[data-color="tertiary"] & {
    fill: var(--tertiary);
  }
  .Loading[data-color="grey"] & {
    fill: var(--grey-60);
  }
}
