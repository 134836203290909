@import "../../mixins.scss";

.InputFile {
  margin-bottom: 16px;
}

.InputFile__label {
  @include inputLabel;
}

.InputFile__group {
  @include input();
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &[data-only-button="true"] {
    background: transparent;
    border: none;
  }

  &[data-dragging="true"] {
    border: dashed 1px var(--grey-20);
    background-color: var(--grey-10) !important;
  }
}

.InputFile__fileName {
  @include input();
  border: none !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 1 !important;
}

.InputFile__field {
  position: absolute;
  left: -100vw;
  opacity: 0;
}

.InputFile__trash {
  margin-right: 5px;
}

.InputFile__button {
  @include input();

  background-color: var(--grey-60);
  color: #fff;
  width: 150px;
  text-align: center;
  border: none;
  flex: 0 0 150px;
  cursor: pointer;

  &[data-button-disabled="true"] {
    cursor: default;
  }

  // &[data-full="true"] {
  //   width: 100%;
  //   flex: 0 0 100%;
  // }
}
