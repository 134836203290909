.blur {
  filter: blur(5px);
}
.productWrap{
  width:23%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap:10px;
}
.showRoomTxt{
  display: flex;
  justify-content: center;
  align-items: center;
}
.productsWrap{
  padding-top: 20px;
  padding-bottom: 20px;;
  display: flex;
  flex-wrap:wrap;
  gap:10px;
  width: 100%;
}
.product{
  width: 100%;
  aspect-ratio: 1;
  background-color: rgb(238, 238, 238);
  border: 1px solid grey;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.eventInput{
  font-size: 20px;
  padding: 8px;
  width: 100%;
  border-radius:5px;
  border:0px;
  background-color:white;
  
}
.eventInfoTitle{
  display:flex;
  justify-content: center;
}
.Exhibitor__outlet {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(black, 0.5);
  z-index: 9;
  overflow: auto;
  padding: 150px 20px;
}
.Exhibitor__outlet__content {
  margin: auto;
  max-width: 860px;
  background-color: white;
  border-radius: 4px;
}
.Button_product_add{
  padding: 10px 20px!important;
  background-color: #28AB6E;
}
.background{
  position: absolute;
  top: 0;
  left: 0;
  height: 130%;
  width: 100vw;
}
.ExhibitorList__outlet__close {
  z-index: 999999;
  display: flex;
  color: black;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
}
.ExhibitorList__outlet__content {
  margin: auto;
  max-width: 900px;
  background-color: white;
  border-radius: 20px;
}
.blur {
  filter: blur(5px);
}
.ExhibitorList__outlet {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(black, 0.5);
  z-index: 9;
  overflow: auto;
  padding: 150px 50px;
}