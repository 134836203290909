@import "./tools.scss";

$border: (
  "arrowColor": (
    property: border-color,
    class: arrowColor,
    pseudo: "::before",
    values: $colors,
  ),
  "arrowBg": (
    property: background-color,
    class: arrowBg,
    pseudo: "::before",
    values: $colors,
  ),
  "arrowDirection": (
    property: content,
    class: arrowDirection,
    pseudo: "::before",
    content: true,
    values: (
      top: "",
      left: "",
      bottom: "",
      right: "",
    ),
  ),
);

$arrowSize: 16px;

[class^="arrow"],
[class*=" arrow"] {
  position: relative;
}
[class^="arrow"]::before,
[class*=" arrow"]::before {
  position: absolute;
  width: $arrowSize;
  height: $arrowSize;

  border-top-width: 1px;
  border-top-style: solid;
  border-left-width: 1px;
  border-left-style: solid;
}

[class^="arrowDirectiontop"]::before,
[class*=" arrowDirectiontop"]::before {
  top: 0;
  left: 50%;
  margin-top: calc(#{$arrowSize} / -2 - 1px);
  transform: translateX(-50%) rotate(45deg);
}

[class^="arrowDirectionbottom"]::before,
[class*=" arrowDirectionbottom"]::before {
  bottom: 0;
  left: 50%;
  margin-bottom: calc(#{$arrowSize} / -2 - 1px);
  transform: translateX(-50%) rotate(-135deg);
}

[class^="arrowDirectionleft"]::before,
[class*=" arrowDirectionleft"]::before {
  left: 0;
  top: 50%;
  margin-left: calc(#{$arrowSize} / -2 - 1px);
  transform: translateY(-50%) rotate(-45deg);
}

[class^="arrowDirectionright"]::before,
[class*=" arrowDirectionright"]::before {
  right: 0;
  top: 50%;
  margin-right: calc(#{$arrowSize} / -2 - 1px);
  transform: translateY(-50%) rotate(135deg);
}

@include render($border);
