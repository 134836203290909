@import "../../mixins.scss";

.InputSwitch {
  display: flex;
  justify-content: center;
}

.InputSwitch__field {
  position: absolute;
  left: -100vw;
  opacity: 0;
}

.InputSwitch__group {
  display: flex;
}

.InputSwitch__label {
  @include inputLabel;
  margin: 0;
}

.Checkbox__status {
  position: relative;
  height: 25px;
  width: 55px;
  border-radius: 50px;
  background-color: var(--alert);
  transition: background-color 0.2s ease;

  &::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    height: calc(100% - 4px);
    aspect-ratio: 1;
    background: white;
    border-radius: 50px;
    transition: transform 0.2s ease;
  }

  .InputSwitch__field:checked ~ & {
    background-color: var(--success);

    &::after {
      transform: translateX(30px);
    }
  }

  span:nth-child(1) {
    display: none;
    position: absolute;
    top: 0;
    left: 6px;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    color: white;

    .InputSwitch__field:checked ~ & {
      display: flex;
    }
  }

  span:nth-child(2) {
    position: absolute;
    top: 0;
    right: 6px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    color: white;
    .InputSwitch__field:checked ~ & {
      display: none;
    }
  }
}
