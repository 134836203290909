.CreateProduct_title {
    font-size: 48px;
    font-weight: 700;
    line-height: 40px;
    font-family: Roboto, sans-serif;
}

.CreateProduct_icon_container {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    height: 100%;
    padding-bottom: 2px;
}

.yearListContainer {
    overflow: hidden;
    min-height: 48px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.yearListContainerScroll {
    position: relative;
    display: inline-block;
    flex: 1 1 auto;
    white-space: nowrap;
    overflow-x: hidden;
    width: 100%;
    overflow: hidden;
    margin-bottom: 0px;
}

.tabButton {
    color: #28ab6e;
    min-height: 48px;
    max-width: 360px;
    min-width: 90px;
    padding: 12px 16px;
    text-align: center;
    background-color: transparent;
    display: inline-flex;
    line-height: 1.25;
    flex-direction: column;
    font-weight: 400;
    font-size: 24px;
}

.tabButton_selected {
    font-weight: 700;
    border-bottom: 2px solid  #28ab6e;
}

.productForm {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
}

.productFormHeader {
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid #ccc;
}

.productFormHeaderTitle {
    font-size: 24px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
}
