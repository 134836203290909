@import "../../mixins.scss";

.Popover {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.Popover__wrap {
}

.Popover__button {
  @include clear-button();

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Popover__icon {
  font-size: 14px;
}

.Popover__container {
  z-index: 999;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 300ms ease;

  .Popover[data-position="top"] & {
    display: flex;
    justify-content: flex-end;
    bottom: calc(100% + 10px);
    right: 50%;
    width: 50vw;
    max-width: 350px;
  }

  .Popover[data-position="right"] & {
    display: flex;
    justify-content: flex-start;
    top: 50%;
    left: calc(100% + 10px);
    width: 50vw;
    max-width: 350px;
  }

  .Popover[data-position="left"] & {
    display: flex;
    justify-content: flex-end;
    top: 50%;
    right: calc(100% + 10px);
    width: 50vw;
    max-width: 350px;
  }

  .Popover[data-position="bottom"] & {
    display: flex;
    justify-content: flex-end;
    top: calc(100% + 10px);
    right: 50%;
    width: 50vw;
    max-width: 350px;
  }

  &[aria-hidden="false"] {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}

.Popover__content {
  pointer-events: auto;
  padding: 16px 20px;
  background: hsla(0, 0%, 100%, 1);
  box-shadow: 0px 1px 8px hsla(231, 23%, 78%, 0.3);
  border: 1px solid hsla(217, 16%, 90%, 1);
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;

  .Popover[data-position="top"] & {
    transform: translateX(50%);
  }
  .Popover[data-position="right"] & {
    transform: translateY(-50%);
  }
  .Popover[data-position="left"] & {
    transform: translateY(-50%);
  }
  .Popover[data-position="bottom"] & {
    transform: translateX(50%);
  }
}
