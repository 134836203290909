@import "../../mixins.scss";

.InputSlider {
  margin-bottom: 16px;
}

.InputSlider__label {
  @include inputLabel;
  margin-bottom: 30px;
}

.InputSlider__group {
  position: relative;
}

@include global(".rc-tooltip") {
  z-index: 0;
}

@include global(".rc-slider-handle") {
  background-color: #fff !important;
  border: none !important;
  opacity: 1 !important;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25) !important;
}

@include global(".rc-slider-handle.rc-slider-handle-dragging") {
  border-color: #fff !important;
  border: none !important;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25) !important;
}

@include global(".rc-slider-track") {
  background-color: #666 !important;
}

@include global(".rc-slider-rail") {
  background-color: #ccc !important;
}

@include global(".rc-slider-disabled") {
  background-color: transparent !important;
}
@include global(".rc-slider-disabled .rc-slider-track") {
  background-color: #aaa !important;
}
@include global(".rc-slider-disabled .rc-slider-rail") {
  background-color: #eee !important;
}

@include global(".rc-tooltip-inner") {
  background-color: transparent !important;
  box-shadow: none;
  margin-bottom: -15px;
}

@include global(".rc-tooltip-arrow") {
  display: none;
}
