@import "../../mixins.scss";

.Div {
  position: relative;
  cursor: pointer;

  &[data-disabled="true"] {
    cursor: default;
    opacity: 0.5;
  }

  &:focus-visible {
    outline: var(--primary) solid 2px;
    outline-offset: 3px;
    outline-style: auto;
  }
}

.Button {
  @include clear-button();

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &[data-appearance="link"] {
    &[disabled] {
      cursor: default;
      opacity: 0.5;
    }
  }

  &[data-appearance="button"] {
    margin-bottom: 15px;
    // background-color: var(--primary);
    // color: var(--white);
    padding: 10px 40px;
    //border-radius: 5px;
    min-width: 170px;
    text-align: center;

    &[disabled] {
      cursor: default;
      opacity: 0.5;
      background-color: var(--grey-light);
    }
  }

  &[data-appearance="small"] {
    // margin-bottom: 15px;
    // background-color: var(--primary);
    // color: var(--white);
    padding: 5px 20px;
    border-radius: 5px;
    // min-width: 170px;
    text-align: center;

    &[disabled] {
      cursor: default;
      opacity: 0.5;
      background-color: var(--grey-light);
    }
  }

  &[data-center="true"] {
    margin-left: auto;
    margin-right: auto;
  }

  &[data-full="true"] {
    width: 100%;
  }

  &[data-loading="true"] {
    color: transparent;
  }
}

.progress {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
}

.progress__status {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform 0.3s ease;
}
