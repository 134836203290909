.productMainSection {
  border-bottom: 1px solid hsla(0, 0%, 62%, 0.4);
}
.productTitleSection {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #555;
  padding: 15px 40px;
  font-weight: 700;
  cursor: pointer;
}

.productTitleSection:hover {
  background-color: #EEEEEE;
}

.productTitle:hover {
  color:#28ab6e
}

.productTitle {
  font-size: 17px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.productTitleLink {
  text-decoration: none;
  color: #666;
  font-size: 17px;
  transition: .3s;
}

.productContentSection_open {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: 10px;
}

.productContentSection_millesime {
  overflow: hidden;
    width: 100%;
    padding: 0 40px 15px 90px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #666;
    font-size: 17px;
    gap: 10px;
}

.millesimeRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.millesimeYear {
  display: flex;
  align-items: center;
  gap: 20px;
}

.productDelete {
  display: flex;
  align-items: center;
  color: #cf2a2a;
  cursor: pointer;
}

.productLink {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #28ab6e;
    gap: 5px;
    background: none;
    border: none;
    font-size: 17px;
    cursor: pointer;
}

.ProductSearchButton {
  padding: 10px 0px !important;
}