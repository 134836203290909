.Loading {
  position: relative;
}

.Loading__loader {
  display: flex;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
