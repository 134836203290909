.buttonExhibitorListHeader{
  background-color: initial;
  padding: 16px 32px;
  border-radius: 16px;
  margin-left: 10px;
  border: none;
}
.iconExhibitorListHeader{
  padding: 10px 32px;
  border-radius: 16px;
}
.iconExhibitorListHeader:hover{
  background: var(--Background-Positive-Tertiary-Hover, #CFF7D3);
}
.justifyBetwen{
  display: flex;
  justify-content: space-between;
}
.buttonExhibitorListHeader__active{
  background-color: initial;
  padding: 16px 32px;
  border-radius: 16px;
  margin-left: 10px;
  border: none;
  font-weight: 500;
  .iconExhibitorListHeader{
    background: var(--Background-Positive-Tertiary-Hover, #CFF7D3);
  }
}