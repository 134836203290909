.Layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.Layout__header {
  z-index: 2000;
  position: sticky;
  top: 0;
  width: 100%;
}

.Layout__main {
  flex: 1 0 auto;
  height: auto;
}

.Layout__encart {
  background-color: var(--proweign-blue-liht);
}

.Layout__link {
  display: inline-block;
}

.Layout__footer {
  width: 100%;
}

.Layout__line {
  background-color: var(--brand-secondary);
  width: 60px;
  height: 6px;
}
