@import "../../mixins";

.InputDate {
  &[data-shake="true"] {
    animation: shake 700ms ease-in-out 0s 1 normal both;
  }
}

.InputDate__label {
  @include inputLabel;
}

.InputDate__fieldGroup {
  @include input();

  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;

  &[data-big="true"] {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.InputDate__day,
.InputDate__month,
.InputDate__year {
  @include input();

  text-align: center;
  border: none;

  &[data-focus="true"],
  &:focus-visible,
  &:focus {
    box-shadow: none;
  }

  &[data-error="true"] {
    color: var(--alert);
    border: none;
    background-color: transparent;
  }

  &[disabled] {
    color: var(--tertiary);
    border: none;
    background-color: transparent;
    opacity: 1;
  }
}

.InputDate__day {
  width: 65%;
}

.InputDate__month {
  width: 65%;
}

.InputDate__year {
  width: 100%;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px);
  }

  18.5% {
    transform: translateX(5px);
  }

  31.5% {
    transform: translateX(-3px);
  }

  43.5% {
    transform: translateX(2px);
  }

  50% {
    transform: translateX(0);
  }
}
