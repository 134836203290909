$breakpoints: (
  mobile: 0px,
  phablet: 426px,
  tablet: 577px,
  laptop: 769px,
  desktop: 1025px,
  desktopXL: 1260px,
  xs: 0px,
  sm: 426px,
  md: 577px,
  lg: 769px,
  xl: 1025px,
  xxl: 1260px,
);

/* base = 16px | 1 spacer = 2px = 0.125rem */
$spacer: 0.125rem;

$opacities: (
  "004": 0.04,
  "008": 0.08,
  "010": 0.1,
  "020": 0.2,
  "030": 0.3,
  "040": 0.4,
  "050": 0.5,
  "060": 0.6,
  "070": 0.7,
  "080": 0.8,
  "090": 0.9,
);

/* breakpoints */

@mixin breakpoint($minBreakpoint, $maxBreakpoint: null) {
  $min: null;
  $max: null;

  @if map-has-key($breakpoints, $minBreakpoint) {
    $min: map-get($breakpoints, $minBreakpoint);
  } @else {
    $min: $minBreakpoint;
  }

  @if map-has-key($breakpoints, $maxBreakpoint) {
    $max: map-get($breakpoints, $maxBreakpoint) - 1;
  } @else {
    $max: $maxBreakpoint;
  }

  @if $max {
    @media screen and (min-width: #{$min}) and (max-width: #{$max}) {
      @content;
    }
  } @else {
    @media screen and (min-width: #{$min}) {
      @content;
    }
  }
}

@mixin global($selector) {
  :global(#{$selector}) {
    @content;
  }
}

/* button reset */
@mixin clear-button() {
  vertical-align: top;
  font-family: inherit;
  text-align: left;
  // background: none;
  line-height: normal;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  appearance: none;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  // color: inherit;
  -webkit-border-radius: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;

  &:focus-visible {
    outline: var(--primary) solid 2px;
    outline-offset: 3px;
    outline-style: auto;
  }
}

$arrowStyle: (
  top: (
    origin: 57% 67%,
    transform: rotateZ(-135deg),
  ),
  right: (
    origin: 80% 100%,
    transform: rotateZ(-45deg),
  ),
  bottom: (
    origin: 85% 35%,
    transform: rotateZ(45deg),
  ),
  left: (
    origin: 50% 100%,
    transform: rotateZ(135deg),
  ),
);

@mixin arrow(
  $direction: right,
  $length: 10px,
  $weigth: 1px,
  $color: var(--black)
) {
  content: "";
  display: block;
  width: $length;
  height: $length;
  border-right: $weigth solid $color;
  border-bottom: $weigth solid $color;

  transform-origin: map-get(map-get($arrowStyle, $direction), origin);
  transform: map-get(map-get($arrowStyle, $direction), transform);
}

@mixin container($maxWidth: var(--container)) {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: var(--padding);
  padding-right: var(--padding);
  max-width: calc(#{$maxWidth} + var(--padding) * 2);
  @content;
}

@mixin clear-input {
  border: none;
  vertical-align: top;
  font-family: inherit;
  text-align: inherit;
  background: none;
  line-height: normal;
  border: none;
  padding: 0;
  margin: 0;
  appearance: none;
  outline: none;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  -webkit-border-radius: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@mixin inputLabel {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  color: var(--tertiary);

  [data-asterix] {
    font-style: normal;
    color: var(--primary);
  }

  &[data-error="true"],
  &[data-error="true"] [data-asterix] {
    color: var(--alert);
  }

  &[data-disabled="true"] {
    color: var(--grey-light);
  }

  &[data-disabled="true"] [data-asterix] {
    display: none;
  }
}

@mixin input {
  @include clear-input();

  width: 100%;
  padding: 9px 10px;
  font-size: 18px;
  color: var(--tertiary);
  border: solid 1px var(--grey-medium);
  border-radius: 4px;
  background-color: none;
  caret-color: var(--primary);
  box-shadow: 0 0 0 0 var(--grey-ultra-light);
  transition: box-shadow 100ms ease-in-out;
  background-color: var(--white);

  &::placeholder,
  &[data-placeholder="true"] {
    color: var(--grey-medium);
  }

  &[data-focus="true"],
  &:focus-visible,
  &:focus {
    box-shadow: 0 0 0 3px var(--grey-ultra-light);
  }

  &[data-error="true"] {
    color: var(--alert);
    border: solid 1px var(--alert);
    background-color: rgba(255, 231, 231, 0.5);
  }

  &[data-disabled="true"],
  &[disabled],
  &[class*="react-select__control--is-disabled"] {
    color: var(--grey-medium);
    border: solid 1px var(--grey-medium);
    background-color: rgba(black, 0.05);
    opacity: 0.5;
  }
}
