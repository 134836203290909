.Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  opacity: 0;
  transition: visibility 300ms ease, opacity 300ms ease;

  &[data-visible="true"] {
    visibility: visible;
    opacity: 1;
  }
}

.Modal__close {
  vertical-align: top;
  font-family: inherit;
  text-align: left;
  background: none;
  line-height: normal;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  appearance: none;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  -webkit-border-radius: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
  top: 10px;
  right: 10px;
  position: absolute;
  cursor: pointer;
  padding: 10px;
  background-color: var(--white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  &:focus-visible {
    outline: var(--primary) solid 2px;
    outline-offset: 3px;
    outline-style: auto;
  }
}

.Modal__back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Modal__content {
  position: relative;
  max-height: 100%;
  overflow-y: auto;
  padding: 50px;
  border-radius: 5px;
  background-color: var(--white);
  box-shadow: 2px 2px 30px 0px rgba(0, 0, 0, 0.25);
  transform: translateY(-10px);
  transition: transform 300ms ease;

  .Modal[data-visible="true"] & {
    transform: translateY(0px);
  }
}
