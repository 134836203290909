@import "../../mixins.scss";

.InputTextarea {
  margin-bottom: 16px;
}

.InputTextarea__label {
  @include inputLabel;
}

.InputTextarea__group {
  position: relative;
}

.InputTextarea__field {
  @include input();
  max-width: 100%;
  resize: vertical;
}

.InputTextarea__icons {
  position: absolute;
  top: 50%;
  right: 15px;
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  transform: translateY(-50%);
}

.InputTextarea__valid {
  font-size: 18px;
  color: var(--success);
}
.InputTextarea__notValid {
  font-size: 18px;
  color: var(--alert);
}
.InputTextarea__icon {
  font-size: 18px;
  color: var(--primary);
}
.InputTextarea__passwordButton {
  @include clear-button();

  font-size: 18px;
  color: var(--grey-medium);
}
