@import "../../mixins.scss";

.BlockResponsive {
  margin-left: auto;
  margin-right: auto;

  @include breakpoint(tablet) {
    padding-left: 50px;
    padding-right: 50px;
  }
}
