@import "../../mixins.scss";

.InputText {
  margin-bottom: 16px;
}

.InputText__label {
  @include inputLabel;
}

.InputText__group {
  position: relative;
}

.InputText__field {
  @include input();
}

.InputText__icons {
  position: absolute;
  top: 50%;
  right: 15px;
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  transform: translateY(-50%);
}

.InputText__valid {
  font-size: 18px;
  color: var(--success);
}
.InputText__notValid {
  font-size: 18px;
  color: var(--alert);
}
.InputText__icon {
  font-size: 18px;
  color: var(--primary);
}
.InputText__passwordButton {
  @include clear-button();

  font-size: 18px;
  color: var(--grey-medium);
  background: transparent;
}
