@import "../../mixins.scss";

.InputAutocomplete {
}

.InputAutocomplete__label {
  @include inputLabel;
}

.InputAutocomplete__group {
  position: relative;
  margin-bottom: 20px;
}

.InputAutocomplete__field {
  @include global(".react-select__control") {
    @include input;

    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 1px;

    [class*="react-select__indicator"] path {
      fill: var(--grey-70);
    }

    &[class*="react-select__control--is-disabled"] {
      [class*="react-select__multi-value__remove"] {
        display: none;
      }
      [class*="react-select__indicator"] {
        fill: var(--grey-30);
      }
      [class*="react-select__multi-value__label"] {
        color: var(--grey-50);
      }
    }

    [class*="error"] & {
      [class*="react-select__indicator"] path {
        fill: var(--alert) !important;
      }

      &[class*="react-select__control--is-disabled"] {
        [class*="react-select__indicator"] {
          fill: var(--alert);
        }
        [class*="react-select__multi-value__label"] {
          color: var(--alert);
        }
      }
    }
  }

  &.error {
    @include global(".react-select__control") {
      border-color: var(--alert);
      background-color: rgba(255, 231, 231, 0.5);

      ::placeholder {
        color: var(--alert);
      }

      [class*="react-select__indicator"] path {
        fill: var(--alert) !important;
      }

      &[class*="react-select__control--is-disabled"] {
        [class*="react-select__indicator"] {
          fill: var(--alert);
        }
        [class*="react-select__multi-value__label"] {
          color: var(--alert);
        }
      }
    }
  }
}
