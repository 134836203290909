@import "../../mixins.scss";

.Alert {
  display: flex;
  align-items: center;
  position: relative;
  padding: 20px;
  border-radius: 5px;
  border: solid 1px var(--secondary);
  color: var(--secondary);
  font-weight: 700;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--secondary);
    opacity: 0.2;
  }

  &[data-alert="error"] {
    border: solid 1px var(--alert);
    color: var(--alert);
    &::before {
      background-color: var(--alert);
    }
  }

  &[data-alert="warning"] {
    border: solid 1px var(--warning);
    color: var(--warning);
    &::before {
      background-color: var(--warning);
    }
  }

  &[data-alert="info"] {
    border: solid 1px var(--info);
    color: var(--info);
    &::before {
      background-color: var(--info);
    }
  }

  &[data-alert="success"] {
    border: solid 1px var(--success);
    color: var(--success);
    &::before {
      background-color: var(--success);
    }
  }
}

.Alert__side {
  margin-right: 10px;
}

.Alert__content {
  position: relative;
}
