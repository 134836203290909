@import "./tools.scss";
@import "./bootstrap-icons/font/bootstrap-icons.scss";

$icon: (
  "icon-before": (
    property: content,
    class: iconBefore,
    pseudo: "::before",
    content: true,
    values: $bootstrap-icons-map,
  ),
  "icon-after": (
    property: content,
    class: iconAfter,
    pseudo: "::after",
    content: true,
    values: $bootstrap-icons-map,
  ),
);

@include render($icon);

[class^="iconBefore"]::before,
[class*=" iconBefore"]::before,
[class^="iconAfter"]::after,
[class*=" iconAfter"]::after {
  display: inline-block;
  font-family: "bootstrap-icons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="iconBefore"]::before,
[class*=" iconBefore"]::before {
  margin-right: 8px;
}
[class^="iconAfter"]::after,
[class*=" iconAfter"]::after {
  margin-left: 8px;
}
