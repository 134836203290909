.buttonQuestion{
  background: none;
  border: none;
  cursor: pointer;
}
.hiddenContent {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.visibleContent {
  max-height: 1000px; /* Adjust based on your content */
  transition: max-height 0.5s ease-out;
}
.button{
  border: none;
  background: none;
  cursor: pointer;
}