@import "../../mixins.scss";
.InputSelect {
  margin-bottom: 16px;
}
.InputSelect__label {
  @include inputLabel;
}
.InputSelect__group {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 5px);
    right: 22px;
    height: 100%;
    width: 9px;
    height: 9px;
    transform: translateY(0px) rotateZ(45deg);
    pointer-events: none;
    border-right: solid 2px var(--primary);
    border-bottom: solid 2px var(--primary);
  }
}
.InputSelect__field {
  @include input();

  padding-right: 45px;
}
.InputSelect__icons {
  position: absolute;
  top: 50%;
  right: 15px;
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  transform: translateY(-50%);
}
.InputSelect__valid {
  font-size: 18px;
  color: var(--success);
}
.InputSelect__notValid {
  font-size: 18px;
  color: var(--alert);
}
.InputSelect__icon {
  font-size: 18px;
  color: var(--primary);
}
