@import "./mixins.scss";
@import "./reset.scss";

:root {
  --brand-primary: rgba(53, 59, 133, 1);
  --brand-primary-dark: hsl(208, 100%, 10%);
  --brand-primary-light: #0569b5;
  --brand-secondary: #b71626;
  --brand-tertiary: #e1e0ce;

  --primary-R: 53;
  --primary-G: 59;
  --primary-B: 133;
  --primary: rgb(var(--primary-R), var(--primary-G), var(--primary-B));
  @each $id, $opacity in $opacities {
    --primary-#{$id}: rgba(
      var(--primary-R),
      var(--primary-G),
      var(--primary-B),
      #{$opacity}
    );
  }

  --secondary-R: 33;
  --secondary-G: 33;
  --secondary-B: 41;
  --secondary: rgb(var(--secondary-R), var(--secondary-G), var(--secondary-B));
  @each $id, $opacity in $opacities {
    --secondary-#{$id}: rgba(
      var(--secondary-R),
      var(--secondary-G),
      var(--secondary-B),
      #{$opacity}
    );
  }

  --tertiary-R: 24;
  --tertiary-G: 24;
  --tertiary-B: 34;
  --tertiary: rgb(var(--tertiary-R), var(--tertiary-G), var(--tertiary-B));
  @each $id, $opacity in $opacities {
    --tertiary-#{$id}: rgba(
      var(--tertiary-R),
      var(--tertiary-G),
      var(--tertiary-B),
      #{$opacity}
    );
  }

  --alert: #ea6863;
  --warning: rgb(227, 175, 42);
  --info: rgb(69, 130, 210);
  --success: rgb(0, 163, 3);

  --white: #fff;
  @each $id, $opacity in $opacities {
    --white-#{$id}: rgba(255, 255, 255, #{$opacity});
  }

  --black: #000;

  --grey-cold: #467b95;
  --grey-medium: #9191a1;
  --grey-light: #c0c1c9;
  --grey-ultra-light: #d8dae8;
  --grey-mega-light: #f6f6f6;

  --grey-05: hsl(0, 0%, 95%);
  --grey-10: hsl(0, 0%, 90%);
  --grey-20: hsl(0, 0%, 80%);
  --grey-30: hsl(0, 0%, 70%);
  --grey-40: hsl(0, 0%, 60%);
  --grey-50: hsl(0, 0%, 50%);
  --grey-60: hsl(0, 0%, 40%);
  --grey-70: hsl(0, 0%, 30%);
  --grey-80: hsl(0, 0%, 20%);
  --grey-90: hsl(0, 0%, 10%);
  --transparent: transparent;

  --font: "Roboto";

  /* 1 = 10px = 0.625rem */
  --unit: 0.625rem;

  --container: 1600px;
  --padding: 15px;

  @include breakpoint(md) {
    --padding: 30px;
  }
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  font-size: 16px;
  color: var(--tertiary);
}

body,
input,
textarea,
button {
  // font-family: var(--font), "helvetica", "arial", sans-serif;
  font-family: var(--font), serif;
  -webkit-font-smoothing: antialiased;
}
