.blur {
  filter: blur(5px);
}

.Exhibitor__outlet {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(black, 0.5);
  z-index: 9;
  overflow: auto;
  padding: 150px 20px;
}
.background{
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
.Exhibitor__outlet__close {
  z-index: 999999;
  position: fixed;
  top: 180px;
  right: 30px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.Exhibitor__outlet__content {
  margin: auto;
  max-width: 860px;
  background-color: white;
  border-radius: 4px;
}

.Exhibitor__qrcode {
  mix-blend-mode: multiply;
}
