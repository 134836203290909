.Header {
  z-index: 9;
  position: sticky;
  top: 0;
}

.Header__encart {
  background-color: var(--brand-primary);
}

.Header__line {
  background-color: white;
  width: 60px;
  height: 4px;
}
.Active{
  border-bottom: 2px solid black;
}
.Header__desk {
  @media screen and (max-width: 640px) {
    display: none;
  }
}

.Header__mob {
  @media screen and (min-width: 641px) {
    display: none;
  }
}
