.InputStand {
  position: relative;
  display: flex;
  align-items: center;
  gap: 3px;
}

.InputStand__field {
  border: none;
  vertical-align: top;
  font-family: inherit;
  text-align: inherit;
  background: none;
  line-height: normal;
  border: none;
  padding: 0;
  margin: 0;
  appearance: none;
  outline: none;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  -webkit-border-radius: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 3px;
  width: 50px;
  text-align: left;
  font-weight: bold;
  background-color: #eee;
}

.InputStand__group {
  position: relative;
}

.InputStand__loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}
