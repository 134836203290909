@import "../../mixins.scss";

.InputNumber {
  margin-bottom: 16px;
}

.InputNumber__label {
  @include inputLabel;
}

.InputNumber__group {
  position: relative;
}

.InputNumber__field {
  @include input();
}

.InputNumber__icons {
  position: absolute;
  top: 50%;
  right: 15px;
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  transform: translateY(-50%);
}

.InputNumber__valid {
  font-size: 18px;
  color: var(--success);
}
.InputNumber__notValid {
  font-size: 18px;
  color: var(--alert);
}
.InputNumber__icon {
  font-size: 18px;
  color: var(--primary);
}
.InputNumber__passwordButton {
  @include clear-button();

  font-size: 18px;
  color: var(--grey-medium);
  background: transparent;
}
