@import "../../mixins.scss";

.Circle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  padding-bottom: 1px;

  &.small {
    flex: 0 0 ($spacer * 8);
    width: ($spacer * 8);
    height: ($spacer * 8);
  }

  &.medium {
    flex: 0 0 ($spacer * 12);
    width: ($spacer * 12);
    height: ($spacer * 12);
  }
}
